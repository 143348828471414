import React from "react";
import "./components-css/pricing-policy.css";

const CancellationPolicy = () => {
  return (
    <div className="piracy-policy-container">
      <div className="piracy-policy-content">
        <h1>Cancellation Policy</h1>

        <p>
          Transactions successfully debited by the payment gateway but not
          confirmed back to ISKCON Lucknow System will be deemed failed
          transactions. All such transactions are eligible for refund post
          reconciliation. Such transactions will be refunded in 10-15 working
          days.
        </p>

        <p>All bookings are FINAL. Cancellation is not allowed.</p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
