import React from "react";
import "./components-css/pricing-policy.css";

const PricingPolicy = () => {
  return (
    <div className="piracy-policy-container">
      <div className="piracy-policy-content">
        <h1>Pricing Policy</h1>

        <p>ISKCON Lucknow may offer donors the option to make donations on a recurring basis ("Recurring Donations") to support its various programs,
           services, and initiatives. 
           Recurring Donations help ensure a steady stream of support for ISKCON Lucknow's ongoing mission and activities.
           ISKCON Lucknow reserves the right to modify or discontinue the Recurring Donations program at any time without prior notice to donors.
          Donors may choose to participate in the Recurring Donations program but are not obligated to do so. By opting for Recurring Donations,
           donors agree to be bound by these terms in addition to any other applicable terms and conditions related to their donations
        </p>
        {/* <p>
          The International Society for Krishna Consciousness (ISKCON) respects
          intellectual property rights and prohibits the unauthorized copying,
          distribution, modification, or commercial exploitation of its
          copyrighted materials. This includes, but is not limited to, its
          website content, publications, lectures, and audiovisual materials.
        </p>

        <h2>Permissible Uses</h2>

        <p>
          ISKCON grants permission for the following limited uses of its
          copyrighted materials without prior consent:
        </p>

        <ul>
          <li>
            Downloading and printing materials for personal, non-commercial use.
          </li>
          <li>
            Sharing links to ISKCON's official website and social media pages.
          </li>
          <li>
            Quoting short excerpts from ISKCON's materials with proper
            attribution, including the title, author (if applicable), and
            source.
          </li>
        </ul> */}

        {/* <h2>Prohibited Uses</h2>

        <p>
          The following activities are strictly prohibited without ISKCON's
          express written permission:
        </p>

        <ul>
          <li>
            Copying or distributing ISKCON's materials for commercial gain.
          </li>
          <li>
            Creating derivative works based on ISKCON's materials without
            permission.
          </li>
          <li>
            Uploading ISKCON's materials to unauthorized websites or
            file-sharing services.
          </li>
          <li>
            Removing or altering copyright notices from ISKCON's materials.
          </li>
          <li>
            Misrepresenting the authorship or origin of ISKCON's materials.
          </li>
        </ul> */}

        {/* <h2>Enforcement</h2>

        <p>
          ISKCON reserves the right to take legal action against any individuals
          or organizations that violate this piracy policy. This may include
          seeking injunctive relief, monetary damages, and any other remedies
          available under applicable law.
        </p>

        <h2>Contact</h2>

        <p>
          If you have any questions regarding this piracy policy, please contact
          ISKCON at [insert contact information, e.g., email address or phone
          number].
        </p> */}
      </div>
    </div>
  );
};
export default PricingPolicy;
