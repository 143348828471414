import React from "react";
import "./components-css/pricing-policy.css";

const PrivacyPolicy = () => {
  return (
    <div className="piracy-policy-container">
      <div className="piracy-policy-content">
        <h1>Privacy Policy</h1>

        <p>
          To avail certain services on iskconlucknow.in, users are required to
          provide certain information for the registration process namely: – a)
          your name, b) email address, c) sex, d) age, e) PIN code, f) credit
          card or debit card details etc., and / or your occupation, interests,
          and the like. The Information as supplied by the users enables us to
          improve our sites and provide you the most user-friendly experience.
        </p>

        <h2>Grievance Redressal</h2>

        <p>
          <strong>Redressal Mechanism:</strong> Any complaints, abuse or
          concerns with regards to content and or comment or breach of these
          terms shall be immediately informed to the designated Grievance
          Officer as mentioned below via in writing or through email signed with
          the electronic signature to
        </p>

        <p>
          <strong>The Grievance Officer:</strong>
          <br />
          Sri Sri Radharaman Bihari ISKCON Temple,
          <br />
          Sushant Golf City, Shaheedpath
          <br />
          Lucknow, Uttar Pradesh, India.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
