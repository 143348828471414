import React from "react";
import "./components-css/pricing-policy.css";

const RefundPolicy = () => {
  return (
    <div className="piracy-policy-container">
      <div className="piracy-policy-content">
        <h1>Refund Policy</h1>

        <p>
          Our policy on refund and cancellation of donations received for ISKCON
          on secure online payment gateway as under:
        </p>

        <p>
          a. Any request for cancellations and refund of online donations once
          duly placed on the website, shall not be entertained under any
          circumstances.
        </p>

        <p>
          b. No cash or refund of money will be allowed after completing the
          online donation as it is an extremely cumbersome process. We therefore
          request you to be sure before you donate.
        </p>

        <p>
          c. No refund/cancellation request for the donated amount by any donor
          will be entertained for online donations through the online payment
          gateway after the donation is accepted from our Online Payment Gateway
          service providers unless there is a fraud notification from our
          Payment Gateway Service Providers.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
