import React, { useEffect } from "react";
import "./components-css/donationform.css";
import vigrahSevaImage from "./components-img/IMG20240401083911.jpg";
import gosevaImage from "./components-img/goseva.jpeg";
import niramImage from "./components-img/new-temple.jpeg";
import anandanImage from "./components-img/annandan.png";

// Define DonationCard as a separate component
const DonationCard = ({ paymentButtonId, title, description, imageUrl }) => {
  useEffect(() => {
    const rzpPaymentForm = document.getElementById(
      "rzp_payment_form_" + paymentButtonId
    );

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = paymentButtonId;
      rzpPaymentForm.appendChild(script);
    }
  }, [paymentButtonId]);

  return (
    <div className="donation-card">
      <div className="card-image">
        <img src={imageUrl} alt={title} />
      </div>
      <div className="card-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <form id={"rzp_payment_form_" + paymentButtonId}></form>
      </div>
    </div>
  );
};

// DonationForm component
export const DonationForm = () => {
  useEffect(() => {
    const loadRazorpayScript = () => {
      const script = document.createElement("script");
      script.defer = true;
      script.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
      script.id = "razorpay-embed-btn-js";
      document.body.appendChild(script);
    };

    if (!document.getElementById("razorpay-embed-btn-js")) {
      loadRazorpayScript();
    } else {
      const rzp = window["_rzp_"];
      rzp && rzp.init && rzp.init();
    }
  }, []);

  return (
    <div id="donate" className="image-container">
      <div className="image-placeholder"></div>
      <div className="content">
        <h2>Turn your Money into Prasad</h2>
        <p style={{ marginLeft: "40px", marginRight: "40px" }}>
          Should you wish to contribute financially to a specific area of
          activity, kindly choose an option from the provided alternatives
          below. ISKCON's operations are completely dependent on voluntary
          contributions, making every donation highly important and greatly
          appreciated. Also, please be assured that all donations are processed
          through a secured site.
        </p>
        <div className="donation-cards-container">
          <DonationCard
            paymentButtonId="pl_Om1JFaTxlN00kR"
            title="Vigrah Seva"
            description="Vigraha Seva in loving service to the Lord and deepen their spiritual connection. It is a sacred practice that enables one to experience the grace of the Lord in a tangible and personal way.
            "
            imageUrl={vigrahSevaImage}
          />
          <DonationCard
            paymentButtonId="pl_Om19qhFbWAFZmT"
            title="Annadaan Seva"
            description="Annadaan is the act of donating food to the needy and is considered to be Mahadaan. It is believed that offering food to others is equivalent to offering it to God Himself.
            "
            imageUrl={anandanImage}
          />
          <DonationCard
            paymentButtonId="pl_Om1Cxf45DlwctH"
            title="Nirman Seva"
            description="By contributing to the maintenance and development of the temple, devotees participate in the service of the Lord and His devotees, which is highly meritorious. &#13;&#10;"
            imageUrl={niramImage}
          />
          <DonationCard
            paymentButtonId="pl_Om1G8OxnwzgPOl"
            title="Go Seva"
            description="By serving cows with love and care, we not only catch the attention of Lord Krishna but also please Him. Also, even just showing respect to cows can help eradicate our sinful acts.
            "
            imageUrl={gosevaImage}
          />
        </div>
      </div>
    </div>
  );
};
