import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2>Darshan Timings</h2>
              <div className="section-title">
                <p>
                  Morning: <br />
                  - Mangal Aarti - 4.30 AM to 5 AM <br />
                  - Shringaar Aarti - 7.30 AM to 8 AM <br />
                  - Morning Bhagwatam Class - 8 AM to 9 AM <br />
                  <br />
                  Noon:
                  <br />
                  - Rajbhog Aarti - 12.30 PM to 1 PM <br />
                  <br />
                  Evening: <br />
                  - Utthan Aarti - 4 PM (Winters) | 4.30 PM (Summers) <br />
                  - Sandhya Aarti - 6.30 PM (Winters) | 7 PM (Summers) <br />
                  - Shayan Aarti - 8.30 PM (Winters) | 9 PM (Summers) <br />
                  <br />
                  Afternoon temple closing: <br />
                  - 1 PM to 4.30 PM (Summers) <br />
                  - 1 PM to 4 PM (Winters) <br />
                </p>
              </div>
            </div>
            {/* <div className="col-md-4">
              <h2>Get In Touch</h2>
              <p>
                Please fill out the form below to send us an email and we will
                get back to you as soon as possible.
              </p>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="3"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div> */}
            <div className="col-md-5">
              <div className="contact-item">
                <h2>Contact Info</h2>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {/* {props.data ? props.data.phone : "loading"} */}
                  Aparimay Shyam Das (President) - +917417938708 <br />
                  Bhoktaram Das (Vice President) - +917355560450 <br />
                  Dinadayal Krishna Das (Vice President) - +919026296062 <br />
                  Madhu Smita Das (Vice President) - +919662029320 <br />
                  <br />
                  General Info - +9190265 63920 <br />
                  Guest House Booking - +917390811811 <br />
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="contact-item">
                <h2>Extra</h2>
                <p>
                  <span>
                    <a href="/pricing-policy" target="_blank">
                      <span>Pricing Policy</span>
                    </a>
                  </span>
                </p>
                <p>
                  <a href="/privacy-policy" target="_blank">
                    <span>Privacy Policy</span>
                  </a>
                </p>
                <p>
                  <a href="/terms-and-conditions" target="_blank">
                    <span>Terms and Conditions</span>
                  </a>
                </p>
                <p>
                  <a href="/cancellation-policy" target="_blank">
                    <span>Cancellation Policy</span>
                  </a>
                </p>
                <p>
                  <a href="/refund-policy" target="_blank">
                    <span>Refund Policy</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Iskcon Lucknow </p>
        </div>
      </div>
    </div>
  );
};
