import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Where is our temple?</h2>
              <p style={{ textAlign: "justify" }}>
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <h3>What you experience?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          {" "}
          <img
            src="img/new-temple.jpeg"
            className="img-responsive-img"
            alt=""
          />{" "}
          <div className="about-text">
            <p style={{ textAlign: "justify" }}>
              ISKCON is coming up with the new temple at our campus in ISKCON
              Lucknow. This new temple will be the largest temple in the whole
              of Lucknow. Made with Pure Marble and Pink Sandstone this will be
              the most attractive spiritual destination for all of Lucknow
              Residents and thousands of visitors and devotees.
            </p>
            <p style={{ textAlign: "justify" }}>
              The temple will have Radha Ramanbihari, Sita Ram Lakshman Hanuman
              and Gaur Nitai. Ornate with beautiful Marble Shikhars this will be
              a marvelous sight to behold.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
