import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { DonationForm } from "./components/DonationForm";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import PricingPolicy from "./components/pricing-policy";
import PrivacyPolicy from "./components/privacy-policy";
import TermsAndConditions from "./components/terms-and-conditions";
import CancellationPolicy from "./components/cancellation-policy";
import RefundPolicy from "./components/refund-policy";

const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigation data={landingPageData.Contact} />
                <Header data={landingPageData.Header} />
                <About data={landingPageData.About} />
                <Team data={landingPageData.Team} />
                <Services data={landingPageData.Services} />
                <Features data={landingPageData.Features} />
                <Gallery data={landingPageData.Gallery} />
                <DonationForm />
                <Contact data={landingPageData.Contact} />
              </>
            }
          />
          <Route path="/pricing-policy" element={<PricingPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
