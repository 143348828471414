import React from "react";
import "./components-css/pricing-policy.css";

const TermsAndConditions = () => {
  return (
    <div className="piracy-policy-container">
      <div className="piracy-policy-content">
        <h1>Terms and Conditions</h1>

        <p>
          This website is Copyright by ISKCON Lucknow. All Rights Reserved. The
          ISKCON Lucknow appreciates your feedback. Any information including
          suggestions, questions, comments, creative ideas, graphics or other
          materials submitted to the Temple shall be treated as non-confidential
          unless otherwise stated. You agree that you will not send any
          copyrighted information to us. Physical reprints of material from the
          site are permitted if you reference the URL for each original web page
          and the page title.
        </p>

        <p>
          Website content is subject to change without notice and at the sole
          editorial discretion of ISKCON Lucknow. Other websites may not copy
          pages or articles. Instead, please use a hyperlink to the original
          article on www.iskconlucknow.in . No artwork or images may be copied
          to another website without prior written permission. Websites wishing
          to link to this site may do so. However, we reserve the right to have
          any website remove a link to our site for any reason whatsoever.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
