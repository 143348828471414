import React, { useState, useEffect } from "react";
import logo from "./components-img/logo.png";

export const Navigation = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${
        scrollPosition > 0 ? "scroll-down" : ""
      }`}
    >
      <div className="container">
        <div className="navbar-header">
          <div className="social-icons">
            <a
              href={props.data ? props.data.facebook : "/"}
              className="social-icon"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href={props.data ? props.data.instagram : "/"}
              className="social-icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href={props.data ? props.data.youtube : "/"}
              className="social-icon"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a className="navbar-brand page-scroll" href="#page-top">
              {/* Use the imported logo image */}
              <img src={logo} alt="Logo" className="logo" />
            </a>
            <ul className="donate">
              <li>
                <div
                  className="razorpay-embed-btn"
                  data-url="https://pages.razorpay.com/pl_Om4JVklcma2Qaj/view"
                  data-text="Quick Donate"
                  data-color="#3C00E0"
                  data-size="small"
                ></div>
              </li>
            </ul>
          </div>
          <div className="navbar-contents">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav">
              <li>
                <a href="#team" className="page-scroll">
                  Leaders
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  Services
                </a>
              </li>
              <li>
                <a href="#features" className="page-scroll">
                  Features
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  Gallery
                </a>
              </li>
              <li>
                <a href="#donate" className="page-scroll">
                  Donate
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
